import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import ProjectCard from "../components/ProjectCard"

const WorkTitle = styled("h1")`
  margin-bottom: 1em;
`

const Work = ({ projects, meta }) => (
  <>
    <Helmet
      title={`Proyectos | Últimos trabajos realizados`}
      titleTemplate={`%s | Proyectos | Últimos trabajos realizados`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `Proyectos | Últimos trabajos realizados`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <WorkTitle>Proyectos</WorkTitle>
      <>
        {projects.map((project, i) => (
          <ProjectCard
            key={i}
            category={project.node.data.project_category}
            title={project.node.data.project_title}
            description={project.node.data.project_preview_description}
            thumbnail={project.node.data.project_preview_thumbnail}
            uid={project.node.uid}
          />
        ))}
      </>
    </Layout>
  </>
)

const component = ({ data }) => {
  const projects = data.allPrismicProject.edges
  const meta = data.site.siteMetadata
  if (!projects) return null
  return <Work projects={projects} meta={meta} />
}

export default component

Work.propTypes = {
  projects: PropTypes.array.isRequired,
}

export const query = graphql`
  {
    allPrismicProject {
      edges {
        node {
          data {
            project_title {
              richText
            }
            project_preview_description {
              richText
            }
            project_preview_thumbnail {
              url
            }
            project_category {
              richText
            }
            project_post_date
          }
          uid
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
